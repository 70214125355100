// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../fonts/NotoSansKR-Light.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../fonts/NotoSansKR-Light.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../fonts/NotoSansKR-Light.otf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../fonts/NotoSansKR-Regular.woff2");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../fonts/NotoSansKR-Regular.woff");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../fonts/NotoSansKR-Regular.otf");
var ___CSS_LOADER_URL_IMPORT_6___ = require("../fonts/NotoSansKR-Medium.woff2");
var ___CSS_LOADER_URL_IMPORT_7___ = require("../fonts/NotoSansKR-Medium.woff");
var ___CSS_LOADER_URL_IMPORT_8___ = require("../fonts/NotoSansKR-Medium.otf");
var ___CSS_LOADER_URL_IMPORT_9___ = require("../fonts/KFOlCnqEu92Fr1MmSU5fBBc-.woff");
var ___CSS_LOADER_URL_IMPORT_10___ = require("../fonts/KFOmCnqEu92Fr1Mu4mxM.woff");
var ___CSS_LOADER_URL_IMPORT_11___ = require("../fonts/KFOlCnqEu92Fr1MmEU9fBBc-.woff");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
var ___CSS_LOADER_URL_REPLACEMENT_10___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_10___);
var ___CSS_LOADER_URL_REPLACEMENT_11___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_11___);
// Module
exports.push([module.id, "@charset \"utf-8\";\r\n\r\n@font-face {\r\n\tfont-family: 'Noto Sans KR';\r\n\tfont-style: normal;\r\n\tfont-weight: 300;\r\n\tsrc: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('woff2'),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('woff'),url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('opentype');\r\n}\r\n\r\n@font-face {\r\n\tfont-family: 'Noto Sans KR';\r\n\tfont-style: normal;\r\n\tfont-weight: 400;\r\n\tsrc: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format('woff2'),url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format('woff'),url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format('opentype');\r\n}\r\n\r\n@font-face {\r\n\tfont-family: 'Noto Sans KR';\r\n\tfont-style: normal;\r\n\tfont-weight: 500;\r\n\tsrc: url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ") format('woff2'),url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ") format('woff'),url(" + ___CSS_LOADER_URL_REPLACEMENT_8___ + ") format('opentype');\r\n}\r\n\r\n\r\n@font-face { \r\n\tfont-family: 'Roboto';\r\n\tfont-style: normal; \r\n\tfont-weight: 300;\r\n\tfont-display: swap; \r\n\tsrc: local('Roboto Light'), local('Roboto-Light'), url(" + ___CSS_LOADER_URL_REPLACEMENT_9___ + ") format('woff');\r\n}\r\n@font-face { \r\n\tfont-family: 'Roboto';\r\n\tfont-style: normal;\r\n\tfont-weight: 400; \r\n\tfont-display: swap; \r\n\tsrc: local('Roboto'), local('Roboto-Regular'),\turl(" + ___CSS_LOADER_URL_REPLACEMENT_10___ + ") format('woff');\r\n} \r\n@font-face {\r\n\tfont-family: 'Roboto'; \r\n\tfont-style: normal;\r\n\tfont-weight: 500; \r\n\tfont-display: swap;\r\n\tsrc: local('Roboto Medium'), local('Roboto-Medium'), url(" + ___CSS_LOADER_URL_REPLACEMENT_11___ + ") format('woff'); \r\n}\r\n\r\n", ""]);
// Exports
module.exports = exports;
