import Send from '../auth.client.js'

export default {
  login: async function (data) {
    console.log('api data = ' + JSON.stringify(data))
    return await new Promise((resolve, reject) => {
        console.log(resolve)
        Send.post('/login', data) // 두번째 인자로 body에 넣어줄 파라미터가 들어감
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
  },
  profileLogin: async function (data) {
    console.log(data)

    let logCatCd = sessionStorage.getItem('logCatCd') === '01' ? sessionStorage.getItem('logCatCd') : ''
    return await new Promise((resolve, reject) => {
        console.log(resolve)
        Send.get('/login/' + data.userId + '?orgUserId=' + sessionStorage.getItem('loginId') + '&logCatCd=' + logCatCd, { headers: { jwt: data.jwt } })
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
  },
  logout: async function (jwt) {
    return await new Promise((resolve, reject) => {
        Send.post('/logout', null, { headers: { jwt: jwt } })
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
  },
  adminLogin: async function (data) {
    console.log('api data = ' + JSON.stringify(data))
    return await new Promise((resolve, reject) => {
        console.log(resolve)
        Send.post('/adminLogin', data) // 두번째 인자로 body에 넣어줄 파라미터가 들어감
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
  },
  info: async function (jwt) {
    return await new Promise((resolve, reject) => {
      Send.get('/info', { headers: { jwt: jwt } })
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  valid: async function (data) {
    console.log('api data = ' + JSON.stringify(data))
    return await new Promise((resolve, reject) => {
        console.log(resolve)
        Send.post('/valid', data) // 두번째 인자로 body에 넣어줄 파라미터가 들어감
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
  },
  logInfo: async function (data) {
    console.log('logInfo.userId=============>', data)
    return await new Promise((resolve, reject) => {
        console.log(resolve)
        Send.post('/loginfo', data)
        //Send.get('/loginfo?userId=' + userId, { headers: { jwt: jwt } })
          .then(res => {
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
  }
}
