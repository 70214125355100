import api from '@/api/rest/settle/calcIssue'
import _ from 'lodash'

export default {
  namespaced: true,
  state: {
    userDmdCatCd: 'R',
    imDmdCatCd: 'R',
    etDmdCatCd: 'R',
    tabIndex: null,
    isRefreshFreetimeReq: false,
    isInvoicePublishPop: true,
    isSettleSearchData: false,
    searchParams: {
      fromDt: '',
      toDt: '',
      eiCatCd: 'O',
      dtKnd: '',
      termType: '01'
    },
    freetimeParams: {
      eiCatCd: '',
      dtKnd: '',
      num: '',
      blNo: '',
      cntrNo: ''
    },
    freetimeVat: ''
  },
  actions: {
    async saveUserDmdCatCd ({ commit }) {
      const param = {}
      let dmdData = []
      const eiCates = [
        {
          code: 'M',
          value: 100
        },
        {
          code: 'C',
          value: 50
        },
        {
          code: 'R',
          value: 10
        }
      ]

      await api.getUserDmd(param)
        .then(response => {
          if (response.data) {
            dmdData = response.data
            if (_.has(dmdData, 'etDmdCatCd')) {
              let d1 = eiCates.filter(v => dmdData.imDmdCatCd === v.code)
              let d2 = eiCates.filter(v => dmdData.etDmdCatCd === v.code)
              if (d1.length > 0 && d2.length > 0) {
                let result = {
                  userDmdCatCd: d2[0].code,
                  imDmdCatCd: dmdData.imDmdCatCd,
                  etDmdCatCd: dmdData.etDmdCatCd
                }
                if (d1[0].value > d2[0].value) {
                  result = {
                    userDmdCatCd: d1[0].code,
                    imDmdCatCd: dmdData.imDmdCatCd,
                    etDmdCatCd: dmdData.etDmdCatCd
                  }
                }
                commit('saveUserDmdCatCd', result)
                console.log(result)
              }
            }
          }
        })
    }
  },
  getters: {
    getUserDmdCatCd: (state) => {
      return state.userDmdCatCd
    },
    tabIndex: (state) => {
      return state.tabIndex
    },
    searchParams: (state) => {
      return state.searchParams
    },
    freetimeParams: (state) => {
      return state.freetimeParams
    },
    isRefreshFreetimeReq: (state) => {
      return state.isRefreshFreetimeReq
    },
    isInvoicePublishPop: (state) => {
      return state.isInvoicePublishPop
    },
    isSettleSearchData: (state) => {
      return state.isSettleSearchData
    },
    getImDmdCatCd: (state) => {
      return state.imDmdCatCd
    },
    getEtDmdCatCd: (state) => {
      return state.etDmdCatCd
    },
    getFreetimeVat: (state) => {
      return state.freetimeVat
    }
  },
  mutations: {
    saveUserDmdCatCd: (state, payload) => {
      state.userDmdCatCd = payload.userDmdCatCd
      state.imDmdCatCd = payload.imDmdCatCd
      state.etDmdCatCd = payload.etDmdCatCd
    },
    updateTabIndex (state, payload) {
      state.tabIndex = payload
    },
    updateSearchParams (state, payload) {
      state.searchParams = payload
    },
    updateFreetimeParams (state, payload) {
      state.freetimeParams = payload
    },
    updateIsRefreshFreetimeReq (state, payload) {
      state.isRefreshFreetimeReq = payload
    },
    updateIsInvoicePublishPop (state, payload) {
      state.isInvoicePublishPop = payload
    },
    updateIsSettleSearchData (state, payload) {
      state.isSettleSearchData = payload
    },
    updateFreetimeVat (state, payload) {
      state.freetimeVat = payload
    }
  }
}
