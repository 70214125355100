<template>
  <div class="loading_wrap" v-if="loading">
    <div class="loading">
      <div class="snippet">
        <div class="stage">
          <div><div class="dot-flashing"></div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ESpinner',
  props: {
    loading: {
      type: Boolean,
      required: true
    }
  }
}
</script>
