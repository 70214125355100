import api from '@/api/services/auth'
import store from '../..'
import * as types from '@/store/modules/auth/mutation-types'
import Cookies from 'js-cookie'

export default {
  namespaced: true,
  state: {
    auth: {
      userId: '',
      orgUserId: '',
      userName: '',
      userEname: '',
      userType: '',
      cstCd: '',
      role: '',
      serviceLang: 'KOR',
      login: false
    },
    lock: false,
    savedUserId: localStorage.getItem('savedUserId') ?? '',
    serviceLang: 'KOR',
    serviceCtrCd: '',
    jwt: '',
    requireLogin: false,
    requireLoginRtnPath: '',
    savedProfileList: [],
    selectedProfile: {},
    compName: '',
    loginResp: [],
    calcEiCatCd: '',
    // ekmtc_admin_login: false
    isEmptyProfileLogin: false
  },
  actions: {
    init ({ commit, state, dispatch }, router) {
      window.onstorage = (event) => {
        if (!event) { event = window.event }
        if (!event.newValue) return
        if (event.key === 'getSessionStorage') {
          if (state.jwt) {
            localStorage.setItem('sessionStorage', JSON.stringify({
              state: state,
              cstCd: sessionStorage.getItem('cstCd'),
              loginId: sessionStorage.getItem('loginId')
            }))
            localStorage.removeItem('sessionStorage')
          }
        } else if (event.key === 'delSessionStorage') {
          dispatch('delLoginInfo')
        } else if (event.key === 'sessionStorage') {
          const sess = JSON.parse(event.newValue)
          const newState = sess.state

          if (state.jwt !== newState.jwt) {
            sessionStorage.setItem('access_token', newState.jwt)

            sessionStorage.setItem('auth', JSON.stringify({
              auth: newState.auth,
              memberDetail: newState.memberDetail,
              jwt: newState.jwt
            }))
            localStorage.setItem('service_lang', newState.auth.serviceLang)
            localStorage.setItem('service_ctrcd', newState.auth.userCtrCd)
            sessionStorage.setItem('cstCd', sess.cstCd)
            sessionStorage.setItem('loginId', sess.loginId)

            commit('auth', newState.auth)
            commit('memberDetail', newState.memberDetail)
            commit('jwt', newState.jwt)
            commit('selectedProfile', newState.selectedProfile)
            commit('loginInfo', newState.loginInfo)
            commit('compName', newState.compName)
            commit('serviceLang', newState.auth.serviceLang)
            commit('serviceCtrCd', newState.auth.userCtrCd)
            commit('requireLogin', false)
            commit('lock', false)

            if (state.requireLoginRtnPath) {
              router.push(state.requireLoginRtnPath)
              commit('requireLoginRtnPath', '')
            }
          }
        }
      }

      localStorage.setItem('getSessionStorage', 'init')
      localStorage.removeItem('getSessionStorage')
    },
    saveLoginAdmin ({ commit, dispatch }, { user, jwt }) {
      console.log('@@@@ enter saveLoginAdmin')
      dispatch('delLoginInfo')

      sessionStorage.setItem('access_token', jwt)

      return new Promise((resolve, reject) => {
        const auth = {
          auth: user,
          jwt: jwt
        }

        sessionStorage.setItem('admin_auth', JSON.stringify(auth))
        localStorage.setItem('service_lang', user.serviceLang)
        localStorage.setItem('service_ctrcd', user.userCtrCd)

        commit('auth', user)
        commit('serviceLang', user.serviceLang)
        commit('serviceCtrCd', user.userCtrCd)
        commit('jwt', jwt)
        commit('requireLogin', false)
        commit('lock', false)
        resolve(auth)
      })
    },
    saveLogin ({ commit, dispatch }, { user, jwt, savedUserId = '', profile, loginInfo }) {
      dispatch('delLoginInfo')

      if (savedUserId) {
        localStorage.setItem('savedUserId', savedUserId)
        commit('savedUserId', savedUserId)
      } else {
        localStorage.removeItem('savedUserId')
        commit('savedUserId', '')
      }

      Cookies.set('access_token', jwt, { path: '/' })

      sessionStorage.setItem('access_token', jwt)
      user.login = true

      return new Promise((resolve, reject) => {
        api.info(jwt)
          .then(infoResp => {
            if (infoResp.headers.respcode === 'C0000') {
              const memberDetail = infoResp.data
              delete memberDetail.compAddr
              delete memberDetail.compEname
              delete memberDetail.compName
              delete memberDetail.fax1
              delete memberDetail.fax2
              delete memberDetail.fax3
              delete memberDetail.recvMailAddr1
              delete memberDetail.recvMailAddr2
              delete memberDetail.tel1
              delete memberDetail.tel2
              delete memberDetail.tel3
              delete memberDetail.tel4
              delete memberDetail.userEmail
              delete memberDetail.userEname
              delete memberDetail.userId
              delete memberDetail.userName

              const auth = {
                auth: user,
                memberDetail: memberDetail,
                jwt: jwt
              }

              sessionStorage.setItem('auth', JSON.stringify(auth))
              localStorage.setItem('service_lang', user.serviceLang)
              localStorage.setItem('service_ctrcd', user.userCtrCd)

              commit('auth', user)
              commit('memberDetail', memberDetail)
              commit('serviceLang', user.serviceLang)
              commit('serviceCtrCd', user.userCtrCd)
              commit('jwt', jwt)
              commit('requireLogin', false)
              commit('lock', false)
              commit('selectedProfile', profile)
              commit('loginInfo', loginInfo)
              commit('updateCalcEiCatCd', '')

              resolve(auth)
            } else {
              reject(infoResp)
            }
          })
      })
    },
    updateServiceLang ({ commit, state }, { serviceLang, serviceCtrCd }) {
      commit('serviceLang', serviceLang)
      commit('serviceCtrCd', serviceCtrCd)
      localStorage.setItem('service_lang', serviceLang)
      localStorage.setItem('service_ctrcd', serviceCtrCd)
    },
    delLoginInfo ({ commit, state }) {
      let serviceLang = state.auth.serviceLang

      commit('auth', {
        userId: '',
        userName: '',
        userEname: '',
        userType: '',
        cstCd: '',
        role: '',
        serviceLang: serviceLang,
        login: false
      })
      commit('memberDetail', {})
      commit('jwt', '')
      commit('lock', false)
      commit('selectedProfile', {})
      commit('loginInfo', {})
      commit('updateCalcEiCatCd', '')
      // commit('updateIsEmptyProfileLogin', false)

      // commit('savedUserId', '')

      sessionStorage.removeItem('auth')
      sessionStorage.removeItem('admin_auth')
      // sessionStorage.removeItem('service_lang')
      sessionStorage.removeItem('access_token')
      // localStorage.removeItem('savedUserId')
    },
    delLogin ({ commit, state, dispatch }) {
      const jwt = state.jwt

      dispatch('delLoginInfo')

      if (this.bc) {
        this.bc.postMessage(JSON.stringify({ cmd: 'del' }))
      }

      if (jwt) {
        return new Promise((resolve, reject) => {
          api.logout(jwt).then(() => {
            localStorage.setItem('delSessionStorage', 'del')
            localStorage.removeItem('delSessionStorage')
            // resolve()

            window.location.href = '/'
          })
        })
      }

      return Promise.resolve()
    },
    bsaDelLogin ({ commit, state, dispatch }) {
      const jwt = state.jwt

      dispatch('delLoginInfo')

      if (this.bc) {
        this.bc.postMessage(JSON.stringify({ cmd: 'del' }))
      }

      if (jwt) {
        return new Promise((resolve, reject) => {
          api.logout(jwt).then(() => {
            localStorage.setItem('delSessionStorage', 'del')
            localStorage.removeItem('delSessionStorage')
            // resolve()

            window.location.href = '/#/bsaMain'
          })
        })
      }

      return Promise.resolve()
    },
    delSaveUserId ({ commit, state, dispatch }) {
      commit('savedUserId', '')
      localStorage.removeItem('savedUserId')
    },
    saveProfileList ({ commit, state }, profileList) {
      commit('savedProfileList', profileList)
    },
    lock ({ commit, state }) {
      commit('lock', true)
    },
    unlock ({ commit, state }) {
      commit('lock', false)
    }
  },
  getters: {
    auth: (state) => {
      return state.auth
    },
    isStaff: (state) => {
      return state.auth?.staffFlag === 'Y'
    },
    serviceLang: (state) => {
      return state.serviceLang
    },
    serviceCtrCd: (state) => {
      return state.serviceCtrCd
    },
    memberDetail: (state) => {
      return state.memberDetail
    },
    loginInfo: (state) => {
      return state.loginInfo
    },
    jwt: (state) => {
      return state.jwt
    },
    requireLogin: (state) => {
      return state.requireLogin
    },
    savedUserId: (state) => {
      return state.savedUserId
    },
    requireLoginRtnPath: (state) => {
      return state.requireLoginRtnPath
    },
    selectedProfile: (state) => {
      return state.selectedProfile
    },
    savedProfileList: (state) => {
      return state.savedProfileList
    },
    compName: (state) => {
      return state.compName
    },
    loginResp: (state) => {
      return state.compName
    },
    lock: (state) => {
      return state.lock
    },
    getCalcEiCatCd: (state) => {
      if (state.calcEiCatCd) {
        return state.calcEiCatCd
      } else {
        // 검색조건에 수입/수출이 변경되지 않아 Vuex에 없는 경우. default 값을 사용자 profile에서 읽는다
        if (state.selectedProfile) {
          // 'A' 수입/수출 전체인데 검색조건 세팅시 기본값 수출로 세팅
          return (state.selectedProfile.eiCatCd === 'A' ? 'O' : state.selectedProfile.eiCatCd)
        } else {
          return state.calcEiCatCd // 프로파일이 없으면 빈값 그대로 리턴
        }
      }
    },
    getIsEmptyProfileLogin: (state) => {
      return state.isEmptyProfileLogin
    }
  },
  mutations: {
    defaultAssign: (state, payload) => {
      const { key, value } = payload
      state.auth[key] = value
    },
    auth: (state, payload) => {
      state.auth = payload
    },
    serviceLang: (state, payload) => {
      if (state.auth) {
        state.auth.serviceLang = payload
      }

      state.serviceLang = payload
    },
    memberDetail: (state, payload) => {
      state.memberDetail = payload
    },
    loginInfo: (state, payload) => {
      state.loginInfo = payload
    },
    loginResp: (state, payload) => {
      state.loginInfo = payload
    },
    jwt: (state, payload) => {
      state.jwt = payload
    },
    requireLogin: (state, payload) => {
      if (state.jwt === '' || state.jwt === null) {
        state.requireLogin = payload
      } else {
        state.requireLogin = false
      }
    },
    savedUserId: (state, payload) => {
      state.savedUserId = payload
    },
    requireLoginRtnPath: (state, payload) => {
      state.requireLoginRtnPath = payload
    },
    selectedProfile: (state, payload) => {
      if (payload != null) {
        if (payload.picNo !== undefined) {
          let profileHistories = localStorage.getItem('profileHistory')
          let loginId = sessionStorage.getItem('loginId')
          let isExist = false
          if (profileHistories === null || profileHistories === undefined || (profileHistories != null && typeof profileHistories === 'object' && !Object.keys(profileHistories).length)) {
            profileHistories = []
          } else {
            profileHistories = JSON.parse(profileHistories)
          }

          for (let i = 0; i < profileHistories.length; i++) {
            if (profileHistories[i].loginId === loginId) {
              profileHistories[i].profile = payload
              isExist = true
              break
            }
          }
          if (!isExist) {
            profileHistories.push({ loginId: loginId, profile: payload })
          }
          localStorage.setItem('profileHistory', JSON.stringify(profileHistories))
        }
      }

      state.selectedProfile = payload
    },
    savedProfileList: (state, payload) => {
      state.savedProfileList = payload
    },
    savedCompName: (state, payload) => {
      state.compName = payload
    },
    lock: (state, payload) => {
      state.lock = payload
    },
    updateCalcEiCatCd (state, payload) {
      state.calcEiCatCd = payload
    },
    updateIsEmptyProfileLogin (state, payload) {
      state.isEmptyProfileLogin = payload
    },
    serviceCtrCd (state, payload) {
      state.serviceCtrCd = payload
    }
  }
}
