import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from '@/store/index'

Vue.use(VueI18n)

function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  console.log(messages)
  return messages
}

const service_langs = {
  KOR: 'ko-KR',
  ENG: 'en-US',
  JPN: 'ja-JP',
  CHN: 'zh-CN'
}

let curr_lang = localStorage.getItem('service_lang')
let curr_ctrcd = localStorage.getItem('service_ctrcd')

if (!curr_lang) {
  const locale = navigator.language?.replace(/(\w+)(-(\w+))*/, '$1$2')

  curr_lang = Object.entries(service_langs)
                          .filter(([k, v], i) => v.includes(locale))
                          .map(([k, v]) => k)
                          .pop() ?? 'ENG'
}

if (!curr_ctrcd) {
  curr_ctrcd = navigator.language?.replace(/(\w+)(-(\w+))*/, '$3')
}

store.dispatch('auth/updateServiceLang', { serviceLang: curr_lang, serviceCtrCd: curr_ctrcd })

export default new VueI18n({
  locale: service_langs[curr_lang],
  messages: loadLocaleMessages(),
  silentTranslationWarn: true
})
