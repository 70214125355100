<template>
  <div @click="lastClick">
    <router-view></router-view>
    <simplert class="simplert" ref="simplert" />
    <!--    <simplert2 :use-icon="false" class="simplert" ref="alertTest" />-->
    <e-spinner :loading="$store.state.LoadingStatus" />

    <!--div class="topl" @click="checkClick($event)" v-show="lock && !showLogin"></div-->
  </div>
</template>

<style>
.topl {
  background: gray;
  opacity: 0.0;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1000;
}
.simplert {
  box-sizing: border-box;
  font-size: 14px;
  line-height: 1.15
}
.simplert *,
.simplert :after,
.simplert :before {
  box-sizing: inherit
}

.simplert {
  position: fixed;
  z-index: 11111;
  top: 0;
  left: 0;
  display: none;
  overflow: auto;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: #000;
  background-color: rgba(0, 0, 0, .4)
}

.simplert--shown {
  display: flex;
  align-items: center
}

.simplert--shown .simplert__content {
  animation-name: zoomIn;
  animation-duration: .3s;
  animation-fill-mode: both
}

.simplert__x { cursor: pointer; position:absolute; top:18px; right:20px; z-index:10009; width:22px; height:22px; border:0; background:url(./assets/images/common/icon_popclose.png) no-repeat 0 0; text-indent:-9999px; }

.simplert__header {
}

.simplert__title {
  margin-top: 15px !important;
  text-align: center !important;
  color: #075BB9 !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.simplert__content {   width:400px;
  height:350px; position:relative; overflow:auto; margin:0 auto 0; padding:20px 20px 5px 20px; border-radius:5px; background:#ffffff; box-shadow: 0px 2px 1px 0 rgba(0, 0, 0, 0.4); }
.simplert__bg_white { background:#ffffff; }

.simplert__content--radius {
  -webkit-border-radius: .5rem;
  -moz-border-radius: .5rem;
  border-radius: .5rem
}

.simplert__body {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  margin-top: 15px !important;
  text-align: center !important;
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.5;
  color: #000;
}
.simplert__icon {
  /*display:block;*/
  margin:30px auto 0;
  width: 60px;
  height: 60px;
  background-color: #E1EDFF;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  border-radius: 50%;
}
.simplert__icon--success { background-image: url(./assets/images/common/icon_notice.png); }
.simplert__icon--notice { background-image: url(./assets/images/common/icon_notice.png); }
.simplert__icon--info{ background-image: url(./assets/images/common/icon_check.png); }

/*.simplert__icon {*/
/*  position: relative;*/
/*  width: 80px;*/
/*  height: 80px;*/
/*  margin: 10px auto;*/
/*  -webkit-border-radius: 50%;*/
/*  -moz-border-radius: 50%;*/
/*  border-radius: 50%*/
/*}*/

/*.simplert__icon--info {*/
/*  border: 4px solid #1a72ff*/
/*}*/

/*.simplert__icon--success {*/
/*  border: 4px solid #58c184*/
/*}*/

.simplert__icon--warning {
  border: 4px solid #fd821b
}

.simplert__icon--error {
  border: 4px solid #dd3b4b
}

.simplert__icon img {
  width: 80px;
  height: 80px
}

.simplert__line {
  position: absolute;
  z-index: 2;
  display: block;
  height: 5px;
  border-radius: 2px
}

.simplert__line--warning {
  position: absolute;
  top: 10px;
  left: 50%;
  width: 5px;
  height: 35px;
  margin-left: -2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  background-color: #fd821b
}

.simplert__line--warning-2 {
  position: absolute;
  bottom: 10px;
  left: 50%;
  width: 7px;
  height: 7px;
  margin-left: -3px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background-color: #fd821b
}

.simplert__line--error {
  top: 35px;
  left: 13px;
  width: 47px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  background-color: #dd3b4b
}

.simplert__line--error-2 {
  top: 35px;
  right: 13px;
  width: 47px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  background-color: #dd3b4b
}

.simplert__footer {
  padding: 1em 0
}

.simplert__close,
.simplert__confirm {
  display: inline-block;
  line-height: 1.2;
  outline: 0;
  font-weight: 700;
  margin: 0 .5em;
  border-radius: .25rem;
  height: 2.5rem;
  min-width: 2.5rem;
  font-size: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: #1a72ff;
  color: #fff;
  cursor: pointer;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none
}

.simplert__close:focus,
.simplert__confirm:focus {
  outline: 0;
  box-shadow: 0 0 0 3px rgb(66 153 225 / 60%)
}

.simplert__close--radius,
.simplert__confirm--radius {
  -webkit-border-radius: .3em;
  -moz-border-radius: .3em;
  border-radius: .3em
}

@keyframes zoomIn {
  from {
    transform: scale3d(.3, .3, .3);
    opacity: 0
  }
  50% {
    opacity: 1
  }
}
</style>

<script>
import ESpinner from '@/components/common/ESpinner'
import store from '@/store'
import moment from 'moment'

export default {
  name: 'App',
  components: {
    ESpinner
  },
  data () {
    return {
      lastClickTime: moment()
    }
  },
  computed: {
    serviceLang () {
      return store.getters['auth/serviceLang']
    },
    lock () {
      return this.$store.getters['auth/lock']
    },
    showLogin () {
      return this.$store.getters['auth/requireLogin']
    }
  },
  watch: {
    serviceLang: function () {
      this.setLocale()
    },
    showLogin: function () {
      this.checkAuth()
    }
  },
  created () {
    this.setLocale()
  },
  mounted () {
    setInterval(() => {
      this.checkAuth()
    }, 1000)
  },
  methods: {
    setLocale () {
      console.log('@@@@@@@ lang ', this.serviceLang)
      if (this.serviceLang === 'KOR') {
        this.$i18n.locale = 'ko-KR'
      } else if (this.serviceLang === 'ENG') {
        this.$i18n.locale = 'en-US'
      } else if (this.serviceLang === 'JPN') {
        this.$i18n.locale = 'ja-JP'
      } else if (this.serviceLang === 'CHN') {
        this.$i18n.locale = 'zh-CN'
      } else {
        this.$i18n.locale = 'ko-KR'
      }
    },
    lastClick () {
      this.lastClickTime = moment()
    },
    async checkClick (e) {
      if (this.lock) {
        e.stopPropagation()

        if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.UTIL010G010.022', { minutes: process.env.VUE_APP_AUTH_TIMEOUT }), useConfirmBtn: true })) {
          this.$store.commit('auth/requireLoginRtnPath', '##unlock##')
          this.$store.commit('auth/requireLogin', true)
        } else {
          this.$store.dispatch('auth/delLogin').then(resolve => {
              location.replace('/')
          })
        }
      }
    },
    checkAuth () {
      const timeout = process.env.VUE_APP_AUTH_TIMEOUT ?? 60 // minutes
      const now = moment()
      const jwt = this.$store.getters['auth/jwt']

      if (jwt && !this.showLogin && !this.lock && now.diff(this.lastClickTime, 'minutes') >= timeout) {
        this.$store.dispatch('auth/lock')
        this.$store.commit('auth/jwt', '')
      }
    }
  }
}
</script>
