import _ from 'lodash'

export default {
  namespaced: true,
  state: {
    suggestionSchedule: null,
    containerList: [],
    dg: [],
    initialDgInfo: {
      vaniDt: '',
      reqConts: '',
      apvCont: '',
      scgAppNo: '',
      apvStsCd: '',
      apvStsNm: '',
      reqRno: '',
      containerDGList: [
        {
          dgSeq: '1',
          imdunNo: '',
          imdgSeq: '',
          imoClasCd: '',
          sbRiskVal1: '',
          sbRiskVal2: '',
          sbRiskVal3: '',
          flsTmpr: '',
          tmprUnitCd: 'C',
          txtPackage: '',
          grsWt: '',
          netWt: '',
          dgPkgGrpCd: '',
          emrTelCtrNo: '',
          emrPicTelNo: '',
          emrTelNo: '',
          emrPicNm: '',
          tchlNm: '',
          dgPkgQty: '',
          dgMatlCd: '',
          isdDgPkgQty: '',
          dgCatCd: '',
          mpltYn: '',
          emsFeCd: '',
          emsSeCd: '',
          lqYn: '',
          imdgGrpCd: '',
          imdgGrpYn: '',
          dgRsdCd: '',
          saptTmprUnitCd: '', /* SAPT 단위 */
          sp386Yn: '',
          saptTmpr: '',
          casNoList: [
            {
              casNo: '',
              rto: ''
            }
          ],
          addInfoMsg: '',
          addInfoMsgList: [],
          repsCont: ''
        }
      ]
    },
    oog: [],
    initialOogInfo: {
      orgLength: '',
      orgWidth: '',
      orgHeight: '',
      itemQty: '',
      underDckCd: '',
      bulkYn: 'N',
      oogCmdtNm: '',
      frgtWt: '',
      tareWtYn: 'N',
      cmdtCd: '',
      itemCont: '',
      apvStsCd: '',
      apvStsNm: '',
      reqRno: ''
    },
    flexi: []
  },
  actions: {
    addContainerDgList ({ commit }, { parentIndex, dg }) {
      commit('addContainerDgList', {
        parentIndex, dg
      })
    },
    removeContainerDgList ({ commit }, { parentIndex, dgList }) {
      commit('removeContainerDgList', {
        parentIndex, dgList
      })
    },
    editContainerDgList ({ commit }, { parentIndex, index, dg }) {
      console.log('@@@@ parentIndex: ', parentIndex)
      console.log('@@@@ index: ', index)
      console.log('@@@@ dg: ', dg)
      commit('setContainerDgList', {
        parentIndex, index, dg
      })
    }
  },
  getters: {
    suggestionSchedule: (state) => {
      return state.suggestionSchedule
    }
  },
  mutations: {
    updateSuggestionSchedule (state, payload) {
      state.suggestionSchedule = payload
    },
    defaultAssign (state, payload) {
      state[payload.key] = payload.value
    },
    setContainerDgList (state, paylaod) {
      const { parentIndex, index, dg } = paylaod
      state.containerList[parentIndex].containerDGList[index] = dg
    },
    addContainerDgList (state, payload) {
      const { parentIndex, dg } = payload
      state.containerList[parentIndex].containerDGList.push(dg)
    },
    removeContainerDgList (state, payload) {
      const { parentIndex, dgList } = payload

      state.containerList[parentIndex].containerDGList = dgList
    },
    setContainerList (state, payload) {
      console.log('@@@@ enter setContainerList')
      console.log('@@@@ payload: ', payload)
      console.log('@@@@ state.containerList: ', state.containerList)
      const { key, value, index } = payload
      state.containerList[index][key] = value
    },
    setContainerListOnlySent (state, payload) {
      const { index, value } = payload
      state.containerList[index] = {
        ...state.containerList[index],
        ...value
      }
      state.containerList = [
        ...state.containerList.slice(0, index),
        {
          ...state.containerList[index],
          ...value
        },
        ...state.containerList.slice(index + 1)
      ]
    }
  }
}
