import axios from 'axios'
import store from '../store/index'

axios.createInstance = function (instanceConfig) {
    const instance = axios.create(instanceConfig)

    instance.defaults.timeout = 600 * 1000

    //공통 헤더, sessionStorage에서 accesstoken 등 관리예정
    instance.interceptors.request.use(function (config) {
        console.time('url:' + config.url)
        // Do something before request is sent
        // console.log('process.env.BASE_URL', process.env) log보기 힘들어 주석처리
        // console.log('request config', config)
        // console.log('axios.defaults.baseURL', this.defaults.baseURL)
        // console.log('request interceptor!!!!')
        let token = sessionStorage.getItem('access_token')
        if (token) {
            config.headers.jwt = token
        }
        let serviceLang = localStorage.getItem('service_lang')
        if (serviceLang) {
            config.headers['service-lang'] = serviceLang
        }

        let serviceCtrCd = localStorage.getItem('service_ctrcd')
        if (serviceCtrCd) {
            config.headers['service-ctrcd'] = serviceCtrCd
        }

        if (location.host.includes('preview')) {
            config.baseURL = config.baseURL.replace('api.', 'api-preview.')
        }

        const selectedProfile = store.getters['auth/selectedProfile']
        // console.log('@@@@ location: ', location)
        // console.log('@@@@ location.href: ', location.href)
        // console.log('@@@@ location.hash: ', location.hash)
        if (location.href.indexOf('common/notice?') < 0) {
          config.headers['service-path'] = location.hash
        }

        //FIXME 로그인전에 프로필정보가 없기때문에 오류발생.
        //우선 프로필정보가 없는경우 헤더에 넣지 않도록 수정
        if (selectedProfile !== undefined && selectedProfile !== null) {
            config.headers['selected-profile'] = (JSON.stringify({
                cstNo: selectedProfile.cstNo,
                picNo: selectedProfile.picNo
            }))
        }

        if (config.spinner ?? true) store.commit('startSpinner')
        return config
    }, function (error) {
        // Do something with request error
        store.commit('endSpinner')
        return Promise.reject(error)
    })

    // Add a response interceptor
    instance.interceptors.response.use(function (response) {
        console.timeEnd('url:' + response.config.url)
        if (response.config.spinner ?? true) store.commit('endSpinner')
        return response
    }, function (error, b) {
        store.commit('endSpinner')

        const code = error.response.status

        if (code === 401) {
            store.dispatch('auth/delLoginInfo').then(() => {
                location.replace('/')
            })
        }

        return Promise.reject(error)
    })

    return instance
}

export default axios
