import _ from 'lodash'

export default {
  namespaced: true,
  state: {
    searchParams: null,
    rfGraphDataList: [],
    cargoSteps: {
      step01: {}, // Empty Container Pick up
      step02: {}, // Port of Loading
      step03: {}, // On Board
      step04: {}, // Port of Discharging
      step05: {}, // Gate Out
      step06: {} // Return
    },
    tsList: [],
    tsInfo: {},
    cargoDetailList: []
  },
  actions: {},
  getters: {
    searchParams: (state) => {
      return state.searchParams
    },
    getRfGraphDataList: (state) => {
      return state.rfGraphDataList
    },
    getCargoSteps: (state) => {
      return state.cargoSteps
    },
    getTsList: (state) => {
      return state.tsList
    },
    getTsInfo: (state) => {
      return state.tsInfo
    },
    getCargoDetailList: (state) => {
      return state.cargoDetailList
    }
  },
  mutations: {
    updateSearchParams (state, payload) {
      state.searchParams = payload
    },
    updateRfGraphDataList (state, payload) {
      state.rfGraphDataList = payload
    },
    updateCargoSteps (state, payload) {
      state.cargoSteps = payload
    },
    updateTsList (state, payload) {
      state.tsList = payload
    },
    updateTsInfo (state, payload) {
      state.tsInfo = payload
    },
    updateCargoDetailList (state, payload) {
      state.cargoDetailList = payload
    }
  }
}
