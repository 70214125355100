import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import settle from './modules/settle'
import schedule from './modules/schedule'
import trans from './modules/trans'
import user from './modules/user'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    LoadingStatus: false,
    LoadingCnt: 0,
    workingEiCatCd: '',
    personalizedFlag: false,
    isOpenPersonalized: false
  },
  mutations: {
    startSpinner (state) {
      state.LoadingStatus = true
      state.LoadingCnt++
    },
    endSpinner (state) {
      state.LoadingCnt--
      if (state.LoadingCnt <= 0) {
        state.LoadingCnt = 0
        state.LoadingStatus = false
      }
    },
    updateWorkingEiCatCd (state, payload) {
      state.workingEiCatCd = payload
    },
    updatePersonalizedFlag (state) {
      state.personalizedFlag = !state.personalizedFlag
    },
    updateIsOpenPersonalized (state, payload) {
      state.isOpenPersonalized = payload
    }
  },
  actions: {
  },
  modules: {
    auth,
    settle: settle,
    schedule: schedule,
    trans: trans,
    user: user
  },
  plugins: [
    createPersistedState({ storage: sessionStorage, paths: ['auth'] })
  ],
  strict: process.env.NODE_ENV === 'development'
})

export default store
