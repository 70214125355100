import _ from 'lodash'

export default {
  namespaced: true,
  state: {
    tabIndex: null,
    searchParams: {
      autCatCd: '00',
      bsnNm: '',
      apvYn: '00',
      fromDt: '',
      toDt: ''
    },
    tableauToken: '',
    appClientId: '43e4dad6-7892-4aca-aa14-6cec97992711',
    appSecretKey: 'h3TBpRhKc1TJaRgAgti8IV8Zs+aWBEj//j6OM5966b8=',
    appSecretId: 'c6afde19-fb31-4248-aebd-8b25323cec38'
  },
  actions: {
  },
  getters: {
    tabIndex: (state) => {
      return state.tabIndex
    },
    searchParams: (state) => {
      return state.searchParams
    },
    getToken: (state) => {
      return state.tableauToken
    },
    getClientId: (state) => {
      return state.appClientId
    },
    getSecretKey: (state) => {
      return state.appSecretKey
    },
    getSecretId: (state) => {
      return state.appSecretId
    }
  },
  mutations: {
    updateTabIndex (state, payload) {
      state.tabIndex = payload
    },
    updateSearchParams (state, payload) {
      state.searchParams = payload
    },
    updateToken (state, payload) {
      state.tableauToken = payload
    }
  }
}
