var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { on: { click: _vm.lastClick } },
    [
      _c("router-view"),
      _c("simplert", { ref: "simplert", staticClass: "simplert" }),
      _c("e-spinner", { attrs: { loading: _vm.$store.state.LoadingStatus } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }